import "./Services.css";
import Collapsible from "react-collapsible";

function Services() {
  function TechServices() {
    return (
      <div class="Services-headline-grid">
        <h3>Design + development</h3>
        <h4>
          <div className="Services-headline">
            Squarespace, Shopify, Wordpress, custom
          </div>
        </h4>
      </div>
    );
  }

  function ConsultationServices() {
    return (
      <div class="Services-headline-grid">
        <h3>Consultation + education</h3>
        <h4>
          <div className="Services-headline">
            Level up your tech skills, tech should be for all!
          </div>
        </h4>
      </div>
    );
  }

  return (
    <div className="Services">
      <div>
        <h2>WHAT CAN I DO FOR YOU?</h2>
        <p className="Services-text">
          I can help with a range of things, from helping you customize an
          existing website template to creating a template or entire site from
          scratch. I am currently freelance, and open to short and long term
          projects. I especially enjoy working with fellow dreamers and
          creatives to make their ideas come to digital life. No matter what
          your project is, with my combined skills in developing, designing,
          curating, and writing, I'm sure I can make it amazing.
        </p>
        <Collapsible
          trigger={<TechServices />}
          transitionTime="200"
          style={{ overflow: "auto" }}
        >
          <p className="Services-info">
            ✿ Website creation: I can design and develop your dream site from
            scratch!
            <br />✿ Development collaboration: I love collaborating with
            designers to develop their designs.
            <br />✿ Website maintenance/refresh: Is your existing site's design
            no longer working for you? Something not functioning as it should?
            Or do you just need some help adding new content? Let me take care
            of it!
          </p>
        </Collapsible>
        <Collapsible
          trigger={<ConsultationServices />}
          transitionTime="200"
          style={{ overflow: "auto" }}
        >
          <p className="Services-info">
            ✿ Education for website owners: Let me teach you how to do more
            maintainance on your existing site by yourself.
            <br />✿ Website audits: I'll take a look at your website and let you
            know how the design, functionality, and accessibility could be
            improved.
            <br />✿ Coding lessons for creatives: Are you a fellow designer
            looking to add some coding tricks to your skillset? Let me teach you
            what you want to know.
          </p>
        </Collapsible>
        <div style={{ borderBottom: "1px solid" }} />
      </div>
    </div>
  );
}

export default Services;
