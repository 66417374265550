import "./ContactMobile.css";

function ContactMobile() {
  return (
    <div className="Contact-mobile-container">
      <div className="Contact-mobile">
        <h2>
          <span style={{ fontFamily: "monospace", fontSize: "26px" }}>✉</span>{" "}
          hello@annikadavisdacunha.com{" "}
          <span style={{ fontFamily: "monospace", fontSize: "26px" }}>✉</span>
        </h2>
        <a href="mailto:hello@annikadavisdacunha.com">
          hello@annikadavisdacunha.com
        </a>
        <br />
        <h2>❋ social media ❋</h2>
        <a
          href={"https://www.instagram.com/annika__karin"}
          target="_blank"
          className="About-link"
        >
          instagram
        </a>
        <a
          href={"https://www.linkedin.com/in/annikadavis"}
          target="_blank"
          className="About-link"
        >
          linkedin
        </a>
        <a
          href={
            "https://ilovecreatives.com/creative-profiles/annika-davis-da-cunha"
          }
          target="_blank"
          className="About-link"
        >
          ilovecreatives
        </a>
      </div>
    </div>
  );
}

export default ContactMobile;
