import "./About.css";
import annika from "./images/annika.jpeg";

function About() {
  return (
    <div className="About">
      <div className="About-intro">
        <img src={annika} className="About-photo" />
        <header className="About-header">
          <p className="About-text">
            Located in: Porto, Portugal
            <br />
            Education: MA in Art History, certificate in front end web
            development
            <br /> <br />
            I'm Annika, and I'm specialized in web design and development. I
            have 4 years of experience in front end development making websites
            and mobile apps. I first taught myself CSS and HTML as a teenager
            customizing my MySpace and Tumblr pages, and have always enjoyed
            coding as a creative medium. In 2020 I studied JavaScript, React,
            and React Native, earning a certificate in front end development.
            Now I combine my skills in design and development to make amazing,
            unique, beautiful websites. As a queer woman, I'm also passionate
            about promoting diversity in the tech world!
            <br /> <br />
            When I'm not working you can find me djing radio shows, baking,
            knitting, hiking, writing, making art, and spending time with my
            greyhound.
          </p>
        </header>
      </div>
    </div>
  );
}

export default About;
